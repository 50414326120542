
.side-menu-header {
    display: flex;
    padding: 0px 15px;
    justify-content: center;
}
.menu-item {
    display: flex;
    grid-gap: 20px;
    box-sizing: border-box;
    padding: 5px 20px;
    cursor: pointer;
    /* padding: 20px; */
    /* width: max-content; */
    margin: 1rem 2rem;
    align-items: center;
    /* padding: 10px 20px 8px 40px; */
    /* margin: 0.5rem 1rem; */
    font-family: 'Roboto', sans-serif;
  }
  .menu-name {
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    letter-spacing: 0.1px;
    background-color: transparent;

  }
  .menu-item-active {
      /* background-color: #008938; */
      color: #008938;
      border-bottom: 3px solid #F2B700;
      /* margin: 0px 1rem; */
      display: flex;
      align-items: center;
      /* padding: 10px 20px 8px 40px; */
      /* border-radius: 10px; */
      /* color: white;*/
  }

