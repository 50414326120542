.zomato-back{
    background-color: black;
    opacity: 0.8;
    height: 100vh;
    margin:0px;
    padding: 0px;
   display: flex;
   justify-content: center;
   align-items: center;
    color: #f2b700;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
    font-weight: lighter ;
    font-size: 2.5rem;
}
@media (max-width:  1024px ) {
    .zomato-back{
      font-size: 1.5rem;
    }
  }
p{
    padding: 0px;
}
.circle-border {
    width: 200px;
    height: 200px;
    background-image: url("../../resources//images/outline.png");
    background-size: 80% 80%, 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
  }
  
  .logo {
    width: 100px;
    height: 100px;
    background-image: url("../../resources/images/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .loader {
    border: 10px solid #f3f3f3;
    border-radius: 50%;
    border-top: 10px solid #f2b700;
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  