.unsubs-header {
  color: #008938;
  font-family: Arial, Helvetica, sans-serif;
}
.unsubs-form-con {
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
}
.otp-con {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5rem;
  color: black;
  font-family: Arial, Helvetica, sans-serif;
}
.otp-fields-con {
  width: 80%;
}
.otp-tagline {
  line-height: 1.5rem;
}
.unsubscribe-modal-con {
  position: absolute;
  top: 30px;
  background-color: white;
  box-shadow: 24;
  color: black;
  border: none;
  padding: 10px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 500px;
}
.message-tag {
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}
.error-message {
  color: red;
}
.success-message {
  color: #008938;
}
@media (max-width: 1025px) {
  .unsubscribe-modal-con {
    width: fit-content;
    border-radius: 0px;
    height: 100vh;
    top: 0;
  }
  .otp-con {
    width: 95vw;
  }
}
