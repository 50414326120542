.privacy-con {
  padding-top: 30px;
}
.content-div {
  overflow: hidden;
}
.privacy-footer-con {
  position: absolute;
  right: 0;
}
