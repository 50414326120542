.nav-header {
  font-family: 'Roboto', sans-serif;
  display: flex;
  grid-gap: 20px;
  margin-top: 1rem;
  align-items: center;
}
.selected-tab {
  font-size: 16px;  
  color: #008938;
  border-bottom: 3px solid #F2B700;
}
.nav-link:hover {
  color:  #008938;
  cursor: pointer;
}
.nav-link {
  text-decoration: none;
  color: #008938;
  padding: 10px;
  text-transform: uppercase;
  padding-bottom: 5px;
  font-size: 16px;  
  font-weight: 500;
  margin-bottom: 8px;
}
@media (max-width:  1024px){
  .nav-header {
    grid-gap: 1px;
    text-align: center;
 
  }
  .nav-link {
    padding: 5px;
    font-size: 10px;  
  }  
  .selected-tab {
    font-size: 10px;
  }
}
@media (min-width:  2300px){
  .nav-link {
    font-size: 25px;
  }
}

