.campagin-body {
  background-color: black;
  height: 100vh;
  margin: 0px;
  padding: 0px;
  background-image: url(../../resources/images/YTDesktop.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-left: 5rem;
}
.custom-btn {
  width: 8rem;
  border: none;
  cursor: pointer;
  margin-right: 2rem;
  margin-top: 0.5rem;
  overflow: hidden;
}
.btn-radius {
  border-radius: 30px;
}
.swiggy {
  background-color: #fc8019;
}
.zomato {
  background-color: #f82335;
}
.magicpin {
  background-color: #0145c4;
}
.subway-logo {
  width: 15rem;
  margin-top: 5rem;
}
.title {
  color: white;
  font-family: "Roboto", sans-serif;
  font-weight: bolder;
}
.mobile-text {
  display: block;
}
.desktop-text {
  display: none;
}
.content-con {
  width: 40%;
  font-size: xx-large;
}
.btn-con {
  margin-top: 5rem;
}

.custom-btn img {
  width: 100%;
  max-height: 100%;
}

@media (max-width: 800px) {
  .title {
    font-size: 2.1rem;
    margin-top: 3rem;
  }
  .subway-logo {
    width: 10rem;
    margin-top: 3rem;
  }
  .campagin-body {
    background-image: url(../../resources/images/YTMobile-06.webp);
    padding-left: 2rem;
  }
  .btn-con {
    display: flex;
    flex-direction: column;
    width: 100%;
    /* margin-top: 0.5rem; */
    align-items: space-between;
  }
  .custom-btn {
    width: 5.5rem;
    margin-top: 0px;
  }
}
@media (min-width: 801px) {
  .title {
    margin-top: 4rem;
    font-size: 3.5rem;
  }
  .mobile-text {
    display: none;
  }
  .custom-btn {
    height: auto;
    width: 8rem;
  }
  .desktop-text {
    display: block;
  }
  .campagin-body {
    background-position: right;
    background-size: 140% 100%;
  }
  /* .btn-con {
  margin-top: 0.5rem;
    } */
  .subway-logo {
    width: 16rem;
  }
}
@media (min-width: 1300px) {
  .title {
    font-size: 3.5rem;
    margin-top: 6rem;
  }
  .custom-btn {
    width: 8rem;
    margin-right: 3rem;
  }
}
@media (min-width: 2300px) {
  .campagin-body {
    padding-left: 10rem;
    background-position: right;
    background-size: 120% 100%;
  }
  .content-con {
    width: 45%;
    font-size: 69px;
  }
  .title {
    font-size: 3.5rem;
    margin-top: 6rem;
  }
  .subway-logo {
    width: 25rem;
    margin-top: 10rem;
  }
  .custom-btn {
    width: 14rem;
    margin-right: 3rem;
  }
}
