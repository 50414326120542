.bg-black {
  color: black;
  min-height: 100vh;
  width: 100%;
  position: relative;
}

.store-not-found {
  display: flex;
  flex-direction: column;
  font-size: 10vw;

  .not-found-text {
    display: flex;
    justify-content: center;
    /* width: 85vw; */
  }

  img {
    max-height: 300px;
    object-fit: contain;
  }

  @media (max-width: 900px) {
    .not-found-text {
      font-size: 2vw;
    }
  }

  @media (max-width: 800px) {
    .not-found-text {
      font-size: 2.5vw;
    }
  }

  @media (max-width: 600px) {
    .not-found-text {
      font-size: 3vw;
    }
  }

  @media (max-width: 450px) {
    .not-found-text {
      width: 100vw;
      font-size: 3.5vw;
    }
  }

  @media (max-width: 400px) {
    .not-found-text {
      width: 100vw;
      font-size: 4vw;
    }
  }
}

.store-footer {
  background: #008938;
  bottom: 0px;
  position: relative;
  background-repeat: none;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  font-family: "Roboto", sans-serif;
  padding-bottom: 0.5rem;
  margin-top: 3rem;
  color: white;
  font-size: 14px;
  background-size: cover;
}

.footer-text {
  padding-left: 100px;
  padding-right: 100px;
}

.store-sm-logo {
  width: 50px;
  position: absolute;
  right: 30px;
  bottom: 9rem;
}

.filters-con {
  background-color: green;
  padding: 2rem 0px 2rem 2rem;
  width: 100%;
}

.title {
  text-transform: uppercase;
  font-size: 20px;
  margin: 0px;
  margin-bottom: 15px;
}

.store-content-con {
  width: 100%;
  border-bottom: 1px solid gray;
  padding: 2rem 0px 1rem 0px;
}

.sub-logo {
  width: 70px;
  height: 70px;
}

.icon-con {
  display: flex;
  align-items: center;
}

.address {
  font-size: 20px;
  margin-top: 10px;
}

.phone {
  margin-top: 0px;
  margin-left: 2.3rem;
}

.p-m-2 {
  margin: 2px;
}

.store-details {
  width: 100%;
}

.store-not-found {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 10rem;
  font-size: 25px;
}

.display-name {
  margin-bottom: 0px;
}

.store-locator-con {
  margin: auto;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
  width: 80%;
}

@media (max-width: 1024px) {
  .footer-text {
    padding-left: 15px !important;
    font-size: 8px;
  }

  .phone {
    margin-left: 0px;
  }

  .store-sm-logo {
    width: 30px !important;
    right: 20px !important;
    bottom: 15px !important;
  }

  .store-content-con {
    box-sizing: border-box;
    padding: 20px;
  }

  .filters-con {
    width: auto;
  }

  .address {
    font-size: 17px;
    line-height: 25px;
    word-spacing: 2px;
  }

  .sub-logo {
    width: 50px;
    height: 50px;
  }

  .store-details {
    /* width:80%; */
  }

  .store-locator-con {
    width: 100%;
  }
}

.si-hide-mobile {
  display: block;
}

.si-hide-web {
  display: block;
}

@media (max-width: 1025px) {
  .si-hide-mobile {
    display: none !important;
  }
}

@media (min-width: 1025px) {
  .si-hide-web {
    display: none !important;
  }
}

.location-link {
  margin-left: 2.3rem;
  text-decoration: none;
  color: blue;
}

.store-name {
  margin-left: 2.3rem;
  text-decoration: none;
}

.map-con {
  display: flex;
}

.store-hours {
  margin-top: 0.5rem;
}

@media (max-width: 1024px) {
  .map-con {
    font-size: small;
  }

  .location-link {
    margin-left: 10px;
  }
}
