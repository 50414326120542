.main-item-menu {
  font-family: "Roboto", sans-serif;
  width: 100%;
  position: relative;
  background-color: white;
  overflow-x: hidden;
}
.item-menu {
  height: 1450px;
  background-image: url("../../../../resources/menu-images/GrillsBG.jpg");
  background-position: top;
  background-size: cover;
  position: relative;
}
.menu-main-image {
  width: 800px;
  padding: 3rem 3rem 1rem 3rem;
}
.menu-main-content {
  position: relative;
  padding-left: 3rem;
}
.menu-half-circle {
  width: 200px;
  height: 200px;
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  bottom: -2px;
}
.menu-part2 {
  padding: 2rem 5rem;
  position: relative;
  padding-bottom: 15rem;
}
.green-text {
  font-size: 100px;
  font-weight: 900;
}
.menu-img {
  width: 80%;
  cursor: pointer;
  border-radius: 20px;
}
.menu-discover-card-con {
  overflow: hidden;
  width: 350px;
  border-radius: 35px;
  background-color: white;
  box-shadow: -1px 1px 13px 0px rgba(0, 0, 0, 0.252);
  position: relative;
  border: 1px solid rgba(181, 180, 180, 0.808);
}
.menu-discover-title {
  position: absolute;
  background-color: white;
  padding: 15px;
  bottom: 0px;
  width: 100%;
  font-weight: 500;
  color: black;
}
.craving-more-con {
  width: -webkit-fill-available;
}
.accordian-con {
  background-color: #008045 !important;
  padding: 20px 50px !important;
  color: white !important;
  border-bottom: 2px solid white !important;
}
.extra-green-div {
  background-color: #008045;
  border-bottom: 2px solid white;
  height: 130px;
}
.menu-last-section {
  padding: 4rem 5rem;
  color: #008045;
  display: flex;
  max-width: 100%;
  overflow: hidden;
  position: relative;
  height: 1100px;
}
.menu-tag-line-con {
  display: flex;
  align-items: center;
  color: #008045;
  justify-content: center;
  border-bottom: 1px solid rgba(163, 163, 163, 0.559);
  padding: 10px;
}
.fire-img {
  width: 50px;
}
.crispers-img {
  position: absolute;
  right: -180px;
}
.float-end {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-height: 390px;
}
.float-start {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  max-height: 390px;
}
.menu-dropdown-text {
  padding: 5px 6px;
}
.menu-order-btn-con {
  margin-left: 19rem;
}
.salad-and-wraps {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.salad-imgs-con {
  display: flex;
}
.salad-imgs {
  width: 600px;
}
.treat-and-sides {
  margin-left: 19rem;
}
.cookies-img {
  width: 500px;
}
.modal-cross-div {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.modal-img {
  width: 300px;
  border-radius: 15px;
}
.modal-order-btn-con {
  width: 100%;
  margin-top: 10px;
}
.half-circle-img {
  width: auto;
}
.yellow-text {
  color: #f2b700;
}
.menu-footer-con {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.menu-footer-divider {
  border-top: 2px solid white;
}
.semiCircle {
  width: 20vw;
  height: 10vw;
  background-color: white;
  border-top-left-radius: 20vw;
  border-top-right-radius: 20vw;
}
.veg-con {
  width: -webkit-fill-available;
}
.veg-text {
  text-align: end !important;
}
.mr-30 {
  margin-right: 40%;
  margin-top: 3px;
}
.ml-30 {
  margin-left: 40%;
}
/* Extra small devices (phones, 600px and down) */
@media (max-width: 600px) {
  .menu-main-image {
    width: auto;
    padding: 2rem;
    padding-bottom: 1rem;
  }
  .mr-30 {
    margin-right: 0px;
    margin-top: 1px;
  }
  .ml-30 {
    margin-left: 0px;
  }
  .float-end {
    max-height: 104px;
  }
  .float-start {
    max-height: 104px;
  }
  .menu-main-content {
    padding-left: 2rem;
    padding-right: 1rem;
  }
  .veg-text {
    text-align: start !important;
  }
  .veg-con {
    /* padding-left: 6px; */
    width: 100%;
  }
  .semiCircle {
    width: 40vw;
    height: 20vw;
  }
  .menu-img {
    width: 90%;
    border-radius: 10px;
  }
  .meet-grill-text {
    font-size: medium;
  }
  .menu-card-con {
    width: 95%;
  }
  .item-menu {
    height: 1100px;
  }
  .half-circle-img {
    height: 100px;
    /* height: auto; */
  }
  .menu-half-circle {
    bottom: -1px;
    height: auto;
    z-index: 2;
  }
  .discover-text-img {
    width: -webkit-fill-available;
    padding-bottom: 0px;
  }
  .menu-part2 {
    padding: 2rem 2rem;
    height: 830px;
  }
  .menu-discover-card-con {
    width: 135px;
    border-radius: 10px;
    box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.252);
  }
  .menu-discover-title {
    font-size: 10px !important;
    padding: 5px;
  }
  .accordian-con {
    padding: 10px 30px !important;
  }
  .extra-green-div {
    height: 70px;
  }
  .fire-img {
    width: 13px;
  }
  .menu-last-section {
    padding: 1rem 2rem;
    height: 450px;
  }
  .crispers-img {
    right: -100px;
    width: -webkit-fill-available;
  }
  .create-own-img {
    width: 164px;
  }
  .menu-dropdown-text {
    font-size: 15px !important;
    text-align: end;
  }
  .menu-order-btn-con {
    margin-left: 1rem;
  }
  .salad-img {
    width: 200px;
  }
  .treat-and-sides {
    margin-left: 0px;
  }
  .cookies-img {
    width: 220px;
  }
  .order-now-imgs {
    display: flex;
    margin-bottom: 1rem;
    flex-wrap: wrap;
  }
  .modal-con {
    width: min-content !important;
    padding: 19px !important;
  }
  .modal-img {
    width: 230px;
  }
  .menu-tag-line-con {
    padding: 2px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media (min-width: 600px) and (max-width: 1350px) {
  .menu-main-image {
    width: 60%;
    padding: 2rem;
    padding-bottom: 1rem;
  }
  .menu-main-content {
    padding-left: 2rem;
    padding-right: 1rem;
  }
  /* .menu-img {
    width: 225px;
  } */
  .menu-card-con {
    width: 95%;
  }

  .half-circle-img {
    width: 400px;
    height: fit-content;
  }
  .discover-text-img {
    width: 80%;
    padding-bottom: 0px;
  }
  .menu-part2 {
    padding: 2rem 2rem;
    height: 1330px;
  }
  .menu-discover-card-con {
    width: 225px;
    border-radius: 20px;
    box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.252);
  }
  .menu-discover-title {
    font-size: 20px !important;
    padding: 5px 10px;
  }
  .create-own-img {
    width: 360px;
  }
  .salad-img {
    width: 450px;
  }
  .accordian-con {
    padding: 10px 30px !important;
  }
  .extra-green-div {
    height: 95px;
  }
  .menu-last-section {
    padding: 1rem 2rem;
    height: 1000px;
  }
  .crispers-img {
    right: -55px;
    width: 80%;
  }
  .menu-order-btn-con {
    margin-left: 9rem;
  }
  .treat-and-sides {
    margin-left: 9rem;
  }
  .fire-img {
    width: 35px;
  }
  .item-menu {
    height: 1800px;
  }
}
@media (min-width: 3000px) {
  .menu-main-image {
    width: 50%;
  }
  .item-menu {
    height: max-content;
    padding-bottom: 300px;
  }
  .meet-grill-text {
    font-size: 100px;
  }
  .menu-img {
    width: 70%;
  }
  .discover-text-img {
    width: 50%;
  }
  .discover-img {
    width: 100%;
  }
  .menu-discover-card-con {
    width: 80%;
  }
  .menu-discover-title {
    font-size: 50px !important;
  }
  .create-own-img {
    width: 80%;
  }
  .menu-dropdown-text {
    font-size: 50px !important;
  }
  .salad-img {
    width: 80%;
  }
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root.Mui-expanded {
  margin: 0px !important;
}
.css-67l5gl.Mui-expanded {
  margin: 0px !important;
}
