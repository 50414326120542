.new-home {
  /* background-color: black; */
  color: white;
  min-height: 100vh;
}
.main-header {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
  background: white;
  padding: 31px 0;
  /* height: 154px; */
  height: 15px;
}
.modile-menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tabs-div {
  margin-right: 2rem;
  display: flex;
  align-items: center;
}
.main-sub-logo {
  width: 10vw;
  margin-left: 2rem;
  margin-top: 1rem;
}
.details-con {
  display: flex;
  margin-top: 6rem;
  font-family: "Roboto", sans-serif;
  align-items: center;
  justify-content: center;
}
.sandwitch-img {
  /* width:25rem; */
  position: relative;
  height: 500px;
  width: auto;
  /* height: 40rem; */
}
.about-sandwitch-img {
  /* width:25rem; */
  position: relative;
  width: auto;
  /* height: 40rem; */
}
.desktop-text {
  width: 30vw;
  height: 8vw;
  display: block;
}
.mobile-text {
  width: 12rem;
  height: 5rem;
  display: none;
}
.mobile {
  display: none;
}
.desktop {
  display: block;
}
.content-div {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headers-con {
  width: 800px;
  font-family: "Roboto", sans-serif;
}
.h-1 {
  font-size: 25px;
  margin: 2px;
}
.info-con {
  margin-top: 2rem;
}
.order-at {
  margin: 0px;

  font-size: 20px;
}
.about-footer {
  position: relative;
  bottom: 0px;
  display: flex;
  align-items: center;
  width: 90%;
  justify-content: space-between;
  font-family: "Roboto", sans-serif;
  padding-bottom: 0.5rem;
  margin-top: 2rem;
  padding-left: 8rem;
  color: black;
  font-size: 15px;
}
.sm-logo {
  width: 40px;
}
.video {
  width: 100%;
  height: 100%;
}

.home-order-btn {
  margin-right: 1.5rem;
}
.left-con {
  margin-left: 50px;
}
.video-con {
  width: 800px;
  height: 500px;
}
.contact-con {
  display: flex;
  justify-content: flex-end;
}
.contact-footer {
  width: max-content;
  margin-right: 50px;
}
td {
  padding: 3px 10px;
}
.link {
  color: white;
}
.label {
  color: rgb(21, 189, 21);
  font-size: 17px;
}
.contact-us {
  margin-bottom: 5px;
  padding: 7px;
  font-size: 20px;
}
.mobile-img-text {
  background-color: #0e9046;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}
@media (max-width: 350px) {
  .about-sandwitch-img {
    width: 250px !important;
  }

  .sm-logo {
    bottom: 6rem !important;
  }
  .yellow-text {
    font-size: 10px !important;
  }
  .mobile-about-footer {
    font-size: 5px !important;
  }
}
@media (max-width: 750px) {
  .left-con {
    margin-left: 0px;
  }
  .mobile-text {
    display: block;
  }
  .desktop-text {
    display: none;
  }
  .details-con {
    margin-top: 3rem;
    justify-content: flex-start;
  }
  .main-sub-logo {
    width: 5rem;
    margin-left: 1rem;
  }
  .video {
    width: 100%;
  }

  .video-con {
    width: 90%;
    height: 230px;
    /* height:360px; */
  }
  .headers-con {
    width: 90%;
  }
  .info-con {
    margin-top: 2rem;
  }
  .mobile {
    display: block;
  }
  .order-at {
    font-size: 15px;
  }
  .desktop {
    display: none;
  }
  .about-footer {
    font-size: 7px;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .sm-logo {
    width: 20px;
  }
  .sandwitch-img {
    width: auto;
    height: 20rem;
    left: -30px;
  }
  .home-order-btn {
    margin-right: 0.5rem;
    height: auto;
    width: 6.5rem;
    /* height: min-content; */
    margin-top: 12px;
    display: flex;
    text-align: center;
  }
}
@media (min-width: 751px) and (max-width: 1250px) {
  .desktop-text {
    width: 25rem;
    height: 8rem;
  }
  .upper-section {
    height: 100vh !important;
  }
  .video-con {
    width: 90%;
    height: 410px;
  }
  .headers-con {
    width: 90%;
  }
  .h-1 {
    font-size: medium;
  }
  .order-at {
    font-size: 18px;
  }
  .about-footer {
    padding-right: 2rem;
    padding-left: 2rem;
    /* padding-bottom: 1rem;
        margin-top: 5rem; */
  }
}
@media (min-width: 2300px) {
  .about-footer {
    font-size: 3rem;
  }
  .sm-logo {
    width: 150px;
  }

  .home-order-btn {
    height: 6rem;
    width: 30rem;
    margin-right: 5rem;
    border-radius: 6rem;
  }
  .order-at {
    margin: 5px;
    font-size: 50px;
    margin-bottom: 2rem;
  }
  .video-con {
    width: 2500px;
    height: 1250px;
    /* height:360px; */
  }
  .info-con {
    margin-top: 8rem;
  }
  .desktop-text {
    width: 80%;
    height: 30rem;
    display: block;
  }
  .main-sub-logo {
    width: 30rem;
    margin-left: 5rem;
    margin-top: 3rem;
  }
  .headers-con {
    width: 2500px;
    font-size: 3rem;
    /* font-family: 'Roboto', sans-serif; */
  }
  .h-1 {
    font-size: 60px;
  }
  .sandwitch-img {
    height: 2000px;
    width: auto;
  }
}
@media (max-width: 1024px) {
  .main-header {
    height: 0px;
  }
  .tabs-div {
    margin-right: 10px;
  }
  .home-order-btn {
    /* width: 6.5rem; */
    margin-bottom: 10px;
  }
  .upper-section {
    background-image: url("../../resources/images/mobile-home-bg.webp") !important;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    min-height: 100vh;
    height: auto;
  }
  .grill-con {
    width: 200px !important;
  }
  .text-con {
    margin-left: 30px !important;
    width: 100%;
    /* margin-top: 20px; */
    margin-bottom: 10px;
  }
  .order-at {
    font-size: 15px !important;
  }
  .video-con {
    width: 350px !important;
    height: 200px !important;
    border-radius: 10px !important;
  }
  .footer-text {
    /* padding-left: 10px !important; */
    padding-top: 0.5rem;
  }
  .sm-logo {
    width: 30px !important;
    position: absolute;
    right: 20px !important;
    bottom: 20px !important;
  }

  .home-footer {
    font-size: 8px !important;
  }
  .about-sandwitch-img {
    width: 330px;
  }
  .list-text {
    font-weight: normal !important;
    line-height: normal !important;
  }
  .footer-text {
    /* padding-left: 15px; */
  }
  .mobile-about-footer {
    background: #008938;
    bottom: 0px;
    position: relative;
    background-repeat: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Roboto", sans-serif;
    padding-bottom: 0.5rem;
    color: white;
    font-size: 10px;
    background-size: cover;
    padding: 15px;
  }
  .sm-logo {
    position: absolute;
    width: 30px !important;
    right: 20px !important;
    bottom: 48px !important;
  }
  .img-container {
    justify-content: flex-end !important;
  }
  .second-section {
    padding-top: 1rem !important;
    padding-left: 2rem !important;
    grid-template-columns: 1fr !important;
  }
}
.content-con {
  width: 100%;
  /* min-height: 100vh; */
}
.upper-section {
  background-image: url("../../resources/images/home-bg.webp");
  background-size: 107% 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
}
.grill-con {
  width: 400px;
  margin-top: 50px;
}
.order-btn {
  display: flex;
  flex-direction: column;
}
.order-at {
  margin: 0px;
  font-size: 18px;
}
.text-con {
  margin-left: 1rem;
}
.video-con {
  width: 800px;
  height: 500px;
  border-radius: 20px;
  overflow: hidden;
}
.video-div {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 3rem;
}
.first-section {
  font-family: "Roboto", sans-serif;
  width: 100%;
  position: relative;
}
.home-footer {
  /* background-image: url("../../resources/images/footer-bg.webp"); */
  background-color: white;
  bottom: 0px;
  background-repeat: none;
  display: flex;
  align-items: center;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Roboto", sans-serif;
  padding-bottom: 0.5rem;
  margin-top: 3rem;
  color: black;
  font-size: 14px;
  background-size: cover;
}
.footer-text {
  /* padding-left: 100px;
  padding-right: 100px; */

  margin: auto;
}
.sm-logo {
  width: 50px;
  position: absolute;
  right: 30px;
  bottom: 30px;
}
.about-us-point {
  margin-bottom: 5px;
}
.list-wrapper {
  width: 80%;
  margin-top: 2rem;
}
.list-text {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.525px;
}
.about-us-con {
  padding: 0rem 2rem;
}
.img-container {
  display: flex;
  justify-content: center;
}
.second-section {
  font-family: "Roboto", sans-serif;
  color: #008938;
  word-break: keep-all;
  display: grid;
  align-items: center;
  grid-template-columns: 2fr 1fr;
  padding-top: 0.5rem;
  padding-left: 6rem;
  min-height: 70vh;
}
.yellow-text {
  color: #f2b700;
  font-size: 12px;
}
.web-about-footer {
  background: #008938;
  bottom: 0px;
  position: relative;
  background-repeat: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Roboto", sans-serif;
  padding-bottom: 0.5rem;
  color: white;
  font-size: 14px;
  background-size: cover;
  padding: 15px;
}
.web-yellow-text {
  color: #f2b700;
  font-size: 14px;
}
.mobile-about-footer {
  background: #008938;
  bottom: 0px;
  position: relative;
  background-repeat: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: "Roboto", sans-serif;
  padding-bottom: 0.5rem;
  color: white;
  font-size: 10px;
  background-size: cover;
  padding: 15px;
}
.contact-con {
  display: flex;
  justify-content: space-between;
}
.si-hide-mobile {
  display: block;
}
.sm-logo {
  position: absolute;
  width: 38px;
  right: 20px;
  bottom: 14rem;
}
.si-hide-web {
  display: block;
}
.common-footer-con {
  padding: 0px 100px;
  display: flex;
  width: 40%;
  justify-content: space-between;
  margin-bottom: 2rem;
}
.common-footer-header {
  font-size: 18px;
  color: #008938;
  margin-bottom: 5px;
}
.common-footer-ele {
  margin-bottom: 5px;
  margin-top: 0px;
}
.common-footer-link-ele {
  cursor: pointer;
  width: fit-content;
}
.common-footer-link-ele:hover {
  border-bottom: 1.5px solid;
}
.common-footer-border {
  width: 75%;
  border-top: 2px solid black;
  margin-bottom: 10px;
}
.common-footer-about-us-border {
  margin-bottom: 10px !important;
  width: 90%;
  margin-top: 20px !important;
  border-top: 2px solid white;
  margin: auto;
}
.mb-0 {
  margin-bottom: 0px;
}
.about-us-mobile-common-footer-con {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
}
.yellow-border {
  margin-top: 10px;
  border-top: 1.5px solid #f9ce62;
  width: 80%;
}
@media (max-width: 1025px) {
  .si-hide-mobile {
    display: none !important;
  }
  .common-footer-con {
    width: 95%;
    padding: 0px;
  }

  .common-footer-header {
    font-size: 15px;
  }
  .common-footer-con {
    margin-bottom: 10px;
  }
  .common-footer-ele {
    font-size: 12px;
  }
  .common-footer-about-us-border {
    width: 100%;
  }
  .order-btn {
    width: fit-content;
  }
}
@media (min-width: 1025px) {
  .si-hide-web {
    display: none !important;
  }
  .text-con {
    position: absolute;
    margin-left: 5rem;
  }
}
@media (min-width: 2300px) {
  .main-sub-logo {
    width: 7vw;
    margin-top: 10px;
  }
  .upper-section {
    height: 1400px;
  }
  .grill-con {
    width: 820px;
  }
  .text-con {
    position: absolute;
    margin-left: 5rem;
  }
  .order-at {
    font-size: 40px;
  }
  .video-con {
    width: 50%;
    height: 1000px;
  }
  .list-text {
    font-size: 23px;
    line-height: normal;
  }
  .about-sandwitch-img {
    height: 26rem;
  }
  .sm-logo {
    width: 80px;
  }
  .main-header {
    height: 25px;
  }
  .about-footer {
    font-size: 1rem;
  }
}
