.home-body {
  background-color: black;
  height: 100vh;
  margin:0px;
  padding: 0px;
  background-image: url(../../resources/images/desktop-image.JPG);
  background-position: center;
background-repeat: no-repeat;
background-size: cover;
}
@media (max-width:  1024px ) {
  .home-body {
    background-size: cover;
    background-position: top;
    background-image: url(../../resources/images/mobile-image.JPG);
  }
}
